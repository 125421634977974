.tab-repository {
    .folder-wrapper {
        height: 86vh;
        background: $device-dashboard-list-bg;
        padding: 15px;
        .folder {
            display: flex;
            cursor: pointer;
            align-items: center;
            color: $primary-color;
            margin-bottom: 15px;
            margin-top: 15px;
            &:hover {
                color: $secondary-color;
            }
            .folder-icon {
                width: 40px;
                height: 40px;
                margin-right: 8px;
            }
            p {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .folder-list {
        padding: 15px;
        .header {
            display: flex;
            padding: 0;
            background: none;
            color: $primary-color;
            font-weight: bold;
            font-size: 18px;
            padding-left: 5px;
            justify-content: flex-start;
            .path,
            .arrow-back {
                color: $tabs-text-header-color;
            }

            .arrow-back {
                cursor: pointer;
            }
        }
        .body {
            display: flex;
            flex-wrap: wrap;
            .folder {
                text-align: center;
                cursor: pointer;
                align-items: center;
                color: $primary-color;
                margin-bottom: 15px;
                margin-top: 15px;
                margin-right: 50px;
                &:hover {
                    color: $secondary-color;
                }
                .folder-icon,
                .file-ico {
                    width: 90px;
                    height: 90px;
                }
                .file-ico {
                    color: #ffd700;
                }
                p {
                    font-size: 18px;
                    font-weight: bold;
                    color: $tabs-text-header-color;
                }
            }

            .create-new-item {
                width: 80px;
                height: 80px;
                margin-top: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px dashed $tabs-text-header-color;
                border-radius: 10px;
                &:hover {
                    border-color: #ffd700;
                    .circle {
                        .addIcon {
                            color: #ffd700;
                        }
                    }
                    cursor: cell;
                }
                cursor: pointer;
                .circle {
                    .addIcon {
                        color: $tabs-text-header-color;
                    }
                }
            }
        }
        .react-contextmenu {
            transition: 0.3s;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.75);
            padding: 15px;
            border-radius: 10px;
            .react-contextmenu-item {
                .menu-item {
                    width: 80px;
                    &:hover {
                        color: #fff;
                    }
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .edit {
                    background: #ffd700;
                }
                .delete {
                    background: #ff1744;
                }
                .about {
                    background: $secondary-color;
                }
                .open {
                    background: $available-btn;
                }
            }
        }
        .popup {
            position: relative;
            max-width: 350px;
            margin: 0 auto;
            cursor: default;
            background: #fff;
            border: 1px solid rgba(0, 0, 0, 0.75);
            border-radius: 10px;
            padding: 10px;
            .close-icon {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                width: 30px;
                height: 30px;
                color: $tabs-text-header-color;
                &:hover {
                    color: #ffd700;
                }
            }
            h1 {
                text-align: center;
                font-size: 25px;
                user-select: none;
            }
        }
    }
}


.repositorium {
    height: 100%;
    display: flex;
    padding: 0 20px 15px;
    justify-content: space-around;
    box-sizing: border-box;
    .path-drawer {
        width: 25%;
        // max-height: 85%;
        overflow: auto;
        border: 1px solid rgba(224, 224, 224, 1);
        box-sizing: border-box;
        border-radius: 4px;
    }

    & .MuiDataGrid-window {
        scroll-behavior: smooth;
    }

    .popup {
        position: relative;
        width: 300px;
        height: 320px;
        margin: 0 auto;
        cursor: default;
        background: #fff;
        padding: 30px 40px;
        border-radius: 10px;
        &.small {
            height: 200px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            p {
                font-size: 18px;
                text-align: center;
                padding: 5px 0;
                line-height: 25px;
                span {
                    // text-transform: uppercase;
                    color: #182d44;
                    font-weight: 600;
                }
            }
            .btn-wrap {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .upload-file-btn {
                    height: 45px;
                    width: 40%;
                    border: 2px solid $secondary-color;
                    border-radius: 15px;
                    
                }
            }
        }
        .title {
            color: $primary-color;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 500;
            font-size: 22px;
        }
        .close-icon {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            color: $primary-color;
            opacity: 0.6;
            &:hover {
                color: #ffd700;
            }
        }
        h1 {
            text-align: center;
            font-size: 25px;
            user-select: none;
        }
        .controll-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            .tabs-wrapper {
                padding: 0;
                width: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $secondary-color;
                border-radius: 20px;
                margin-top: 20px;
                box-sizing: border-box;
                height: 36px;
                .tab {
                    padding: 0;
                    width: 50%;
                    min-height: 36px;
                    border: 2px;
                    border-radius: 20px;
                }
                .file,
                .folder {
                    background: $secondary-color;
                    color: #fff;
                }
            }
            .upload-file {
                display: none;
            }
            .icon-button {
                margin-top: 28px;
                svg {
                    width: 80px;
                    height: 80px;
                }
            }
            .file-uploaded {
                color: $secondary-color;
            }
            .upload-file-btn {
                margin: 0 auto;
                height: 50px;
                width: 150px;
                border: 2px solid $secondary-color;
                border-radius: 15px;
                &.disable {
                    opacity: 0.7;
                    &:hover {
                        background: transparent;
                        cursor: inherit;
                    }
                }
                &:not(.disable):hover {
                    background: $secondary-color;
                    color: #fff;
                }
            }
            .tab-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: column;
                background: #fff;
                width: 100%;
                height: 80%;
                padding: 20px 0;
                .rename-file {
                    width: 100%;
                }
                .create-filed {
                    margin: 0 auto;
                    height: 50px;
                    width: 150px;
                    border: 2px solid $secondary-color;
                    border-radius: 15px;
                    &:hover {
                        background: $secondary-color;
                        color: #fff;
                    }
                }
                .filed {
                    // margin-top: 25px;
                    width: 100%;
                }
               
            }
        }
    }
    
    .loading-wrapper {
        position: fixed;
        top: 75px;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10000;

        & .loading-display {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}