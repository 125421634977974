.main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .main-table {
        width: 100%;
        height: calc(100% - 75px);
        position: relative;

        .table-container {
            padding: 0 !important;

            .loader-wrapper {
                height: calc(100vh - 130px);
            }
        }

        .main-table-wrap {
            height: 100%;
            overflow: hidden;
        }

        .run-icon {
            vertical-align: middle;
            color: $available-btn;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
        }

        .info-icon {
            vertical-align: middle;
            color: $tabs-text-header-color;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .main-table.tab-dashboard {
        border-top: 1px solid #ebe7e7;
    }

    .main-table.tab-repositorium {
        .table-container {
            height: 100%;
            width: 100%;
        }
    }

    .main-table.general-table {
        .table-container {
            height: calc(100% - 52px);
            overflow: auto;
            scroll-behavior: smooth;
        }
    }
}
