.logs-container {
	display: flex;
	justify-content: center;
	align-items: center;
	.video-wrapper {
		padding-top: 20px;
		width: 450px;
	}
	.video-controllers {
		background: none;
		bottom: -35px;
		.video-react-volume-menu-button-horizontal {
			display: none;
		}
		.video-react-play-control,
		.video-react-current-time,
		.video-react-time-control,
		.video-react-progress-control,
		.video-react-icon-fullscreen {
			color: $available-btn;
		}
		.video-react-progress-holder {
			border: 1px solid $tabs-text-header-color;
			background-color: $tabs-text-header-color;
			color: $tabs-text-header-color;
			.video-react-load-progress {
				border: 1px solid $tabs-text-header-color;
				background-color: $tabs-text-header-color;
				color: $tabs-text-header-color;
			}
		}
	}
}
