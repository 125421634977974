.board {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 55px);

    .pump-container {
        position: relative;

        .pump-button {
            &:hover {
                cursor: pointer;
                fill: #57606a;
            }
        }

        .pump-message {
            position: absolute;
            top: 75px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 12px;
            color: #ffffff;
            white-space: nowrap;
            text-transform: uppercase;
        }
    }
}
