.device-dashboard {
    display: flex !important;
    overflow: hidden;
    padding: 0 0 !important;
    .list-open {
        width: 30%;
    }
    .list-open ~ .draggable-dashboard {
        left: 20%;
        width: calc(100% - 30%);
    }
    .list-close {
        width: 50px;
    }

    .list-close ~ .draggable-dashboard {
        left: 65px;
        width: calc(100% - 20%);
    }

    .draggable-dashboard {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        top: 5%;
        display: flex;
        flex-wrap: wrap;
        transition: 0.5s linear;
        .skin-wrapper {
            position: relative;
            width: 15%;
            padding: 15px;
            display: flex;
            justify-content: center;
            .dashboard-stream-wrapper {
                background-color: #fff;
                box-sizing: border-box;
                position: absolute;
                padding: 0 0;
                margin: 0 auto;
                max-width: 300px;
                left: 5px;
                top: -20px;
                z-index: 1000;
                border: 0.5px solid;
            }
            .dashboard-stream-wrapper:hover {
                cursor: grab;
            }
            .dashboard-stream-wrapper:active {
                cursor: grabbing;
            }
            .device-panel {
                display: flex;
                justify-content: space-around;
                align-items: center;
                svg { 
                    width: 20px; 
                    height: 20px; 
                }
            }
            .skin-image {
                position: relative;
                top: 0;
                left: 0;
                width: 220px;
                height: 440px;
                z-index: 1000;
            }
            .stream-image-galaxy {
                //To Do: update className according to device model
                position: absolute;
                top: 42px;
                left: 18px;
                width: 185px;
                height: 400px;
                border-radius: 16px;
                background-color: black;
            }
            .stream-image-iphone {
                //To Do: update className according to device model
                position: absolute;
                width: 200px;
                height: 420px;
                top: 25px;
                left: 10px;
                border-radius: 22px;
                background-color: black;
            }
            .stream-video-galaxy {
                position: absolute;
                left: 0;
                top: 40px;
                height: 400px;
            }
            .stream-video-iphone {
                position: absolute;
                left: 15px;
                top: 35px;
                height: 410px;
            }
            .stream-message {
                display: flex;
                justify-content: center;
                align-items: center;
                background: black;
                color: #fff;
                font-size: 18px;
            }
        }
    }

    .device-list {
        background-color: $device-dashboard-list-bg;
        @media only screen and (max-device-width: 1300px) {
            max-width: 260px;
        }
        @media only screen and (min-device-width: 1300px) {
            max-width: 369px;
        }
        height: calc(100vh - 90px);
        transition: width 0.22s ease-in;
        @media only screen and (min-width: 618px) {
            height: calc(100vh - 45px);
        }

        ul {
            li {
                margin: 0 0 !important;
                padding: 0 0 !important;
            }
        }

        .list-header {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 21px;
            line-height: 25px;
            color: #414141;
            padding-top: 25px;
            padding-left: 12px;
            padding-bottom: 14px;
        }

        .device-elem {
            padding-left: 12px;
            font-family: Effra;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 32px;
        }

        .device-close {
            opacity: 0;
        }

        .device {
            .device-item {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;
                .device-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }

        .device:hover {
            color: $device-selected;
            cursor: pointer;
            svg {
                color: $device-selected;
            }
        }

        .mobile:hover {
            cursor: pointer;
            color: $device-selected;
        }

        .device:hover .device-close {
            opacity: 1;
        }

        .device-move {
            padding-right: 5px;
        }

        .device-move:hover {
            cursor: pointer;
        }
    }
}

.MuiListItem-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiListItem-secondaryAction {
    padding-right: 0px !important;
}
