$primary-color: #314f72;
$primary-text-color: #182d44;
$secondary-color: #4d9bd5;
$third-color: #cad8e6;
$background-tabs-wrapper: #e5e5e5;
$text-color-primary: #fff;
$search-bgn: #4b6685;
$device-dashboard-list-bg: #f2f2f2;
$device-selected: #4d9bd5;
$tabs-text-header-color: #828282;
$tab-body-background-color: #f2f2f2;
$available-btn: #53A337;
$right-panel-main-color: #414141;
$header-text-color: #414141;
$text-color: #424242;
