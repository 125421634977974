.tab-logsCenter {
    .MuiBox-root {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        .information-popup-wrapper {
            position: absolute;
            top: 300px;
            background: #fff !important;
            border: 1px solid silver;
            border-radius: 15px;
            width: 25%;
            padding: 10px;
            .tags-info {
                display: flex;
                justify-content: space-between;
                .close-icon {
                    cursor: pointer;
                    &:hover {
                        color: #4d9bd5;
                    }
                }
            }
        }
        .MuiPaper-root {
            width: 100%;
        }
    }
}

tr:hover {
    background: #f2f2f2;
}

.table-row {
    .table-element {
        cursor: pointer;
    }

    .log-element {
        color: #4d9bd5;
        transition: 0.5s;

        &:hover {
            cursor: pointer;
            color: #314f72;
        }
    }
    & .log-info.size{
        height: 24px;
    }

    //dynamic cell width for different screen size
    & .table-element:nth-child(n+1):nth-child(-n+4) .table-content.log-info {
        min-width: 150px;
        width: calc(150px + (180 - 150) * (100vw - 1400px) / (1920 - 1400));
    }
    & .table-element:nth-child(n+5):nth-child(-n+6) .table-content.log-info { 
        min-width: 100px;
        width: calc(100px + (125 - 100) * (100vw - 1400px) / (1920 - 1400));
    } 
    & .table-element:nth-child(n+7):nth-child(-n+8) .table-content.log-info { 
        min-width: 180px;
        width: calc(180px + (245 - 180) * (100vw - 1400px) / (1920 - 1400));
    } 
    & .table-element:nth-child(9) .table-content.log-info { 
        min-width: 80px;
        width: calc(80px + (120 - 80) * (100vw - 1400px) / (1920 - 1400));
    }
}

.table-element-message {
    font-family: Roboto, Helvetica, Arial sans-serif;
    font-size: 14px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.logs-settings{
    height: 24px;
        & .log-menu-wrapper,
        & .device-menu-wrapper {
            height: 100%;
            min-height: 100%;
                & .icon-wrapper {
                    padding: 5px;
                    & .settings-icon {
                        color: #314f72;
                    }
                }   
        } 
}

ul.MuiList-root.MuiMenu-list {
    & li.artifact-item.artifact-menu-item {
        margin: 0;
        color: #000;
        border-radius: 0;
        line-height: 2.5;
        background: none;
        justify-content: center;
        position: relative;
        cursor: default;

        & .MuiTouchRipple-root {
            display: none;
        }

        & .artifact-item-preview {
            &:hover {
                cursor: pointer;
                color: #9f9f9f;
            }
        }

        & .artifact-item-download {
            position: absolute;
            top: 8px;
            right: 2px;
            width: 18px;
            fill: #4e4c4c;
            stroke: transparent;
            transition: 0.3s;
            &:hover {
                cursor: pointer;
                fill: #9f9f9f;
            }
        }
    }
}

.delete-tooltip-wrapper,
.filter-tooltip-wrapper {
    position: fixed;
    top: 68px;
    right: 8px;
    z-index: 1200;
}

.filter-tooltip-wrapper.agent-filter {
    position: absolute;
    top: 5px;
    right: 25px;
    z-index: 10;
}

.delete-tooltip-wrapper {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
}

.filter-panel {
    position: absolute;
    top: 200px;
    width: 200px;
    height: 250px;
    padding: 10px 10px 10px 30px;
    display: flex;
    background: #fff;
    box-shadow: 4px 4px 16px -8px rgba(0, 0, 0, 0.68);
    border: 1px solid silver;
    .close-icon {
        cursor: pointer;
        &:hover {
            color: #4d9bd5;
        }
    }
    form {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: space-around;
        .execute-btn {
            width: 100%;
        }
    }
}

.pagination {
    position: fixed;
    right: 0;
    bottom: 0;
}