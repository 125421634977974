header {
    background: $primary-color;
    padding: 5px 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 45px;
    position: sticky;
    top: 0;
    left: 0;
    h1 {
        font-size: 21px;
        line-height: 2;
        color: $text-color-primary;
        font-family: "roboto";
        cursor: default;
    }
    .control-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        #outlined-basic {
            padding: 5px 10px;
            height: 28px;
        }
        .search-field {
            ::-webkit-search-cancel-button {
                display: none;
            }
            width: 320px;
            margin-right: 50px;

            @media screen and (max-width: 800px) {
                width: 200px;
            }
            label {
                color: $text-color-primary;
                font-style: normal;
                font-weight: normal;
                top: -8px;
            }
            .MuiOutlinedInput-root {
                background: $search-bgn;
                color: $text-color-primary;
            }
            .MuiInputLabel-shrink {
                transform: translate(14px, 3px) scale(0.75);
            }
            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        .user-account {
            position: relative;
            display: flex;
            align-items: center;
            .user-email,
            .user-token {
                color: #fff;
            }
            p {
                margin-left: 10px;
                color: $text-color-primary;
                font-family: "roboto";
                font-size: 14px;
                line-height: 16px;
            }
            .indecator {
                position: absolute;
                width: 15px;
                height: 15px;
                background: orange;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 0;
                left: -8px;
                p {
                    margin: 0;
                    font-size: 10px;
                }
            }
        }
    }
}

.beta-version-logo {
    width: 110px;
    height: 110px;
    position: absolute;
    top: 0;
    left: 0;

    & img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.beta-version-logo + .go-to-list {
    padding: 0 0 0 75px;
}

.go-to-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    .arrow {
        margin-right: 10px;
        display: flex;
    }
    p {
        color: white;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        padding-left: 10px;
        padding-bottom: 3px;
    }
    path {
        fill: white;
    }
}

.go-to-list:hover {
    cursor: pointer;
}
.MuiPopover-root {
    .MuiPaper-root {
        // top: 55px !important;
        right: 70px;
        left: none;
        width: 200px;
        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            .counter {
                position: absolute;
                width: 15px;
                height: 15px;
                background: orange;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 15px;
                right: 10px;
                z-index: 300;
                p {
                    margin: 0;
                    color: #fff;
                    font-size: 10px;
                }
            }

            li {
                margin-top: 10px;
                width: 100%;
                justify-content: center;
                &:hover {
                    transition: 0.5s;
                    border-radius: 30px;
                    background: $device-selected;
                    color: #fff;
                }
            }
        }
    }
}
