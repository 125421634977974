.active-sessions-wrapper,
.token-sessions-wrapper,
.simulators-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 101;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	.token-wrapper {
		width: 100%;
		max-width: 1400px;
		min-width: 300px;
		position: relative;
		border-radius: 10px;
		background: #fff;
		padding: 65px 25px;
		.token-value {
			line-height: 1.5;
			font-size: 20px;
			text-align: center;
			word-break: break-all;
		}
		.close-icon {
			position: absolute;
			right: 15px;
			top: 15px;
			cursor: pointer;
		}
		.copy-icon {
			cursor: pointer;
			position: absolute;
			right: 50%;
			bottom: 18px;
			transform: translateX(-50%);
		}
	}
	.sessions {
		width: 900px;
		border-radius: 10px;
	}
	.simulators-content,
	.sessions {
		background: #fff;
		min-height: 400px;
		max-height: 510px;
		overflow: auto;
		padding: 10px 10px 16px 10px;
		.simulators-popup,
		.popup-header {
			display: flex;
			justify-content: space-between;
			padding: 10px;
			svg {
				&:hover {
					color: $device-selected;
					cursor: pointer;
				}
			}
			.close-icon {
				&:hover {
					opacity: 0.6;
					color: #000;
					cursor: pointer;
				}
			}
		}
		.device-wrapper {
			margin-top: 20px;
			padding-left: 5px;
			padding-right: 5px;
			ul {
				height: 20px;
				list-style-type: none;
				padding: 0;
				margin: 0;
				display: flex;
				justify-content: space-between;
			}
		}
		.simulators-table-head {
			white-space: nowrap;
		}
	}
	.stop-icon {
		color: #828282;
	}
	.start-icon {
		color: #53A337;
	}
	.stop-icon,
	.start-icon {
		font-size: 26px;
		vertical-align: middle;
		&:hover {
			opacity: 0.6;
            cursor: pointer;
		}
	}
}
