.table {
    min-width: 100%;
    .table-header {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
        background: $secondary-color;
        font-family: "Effra";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        white-space: nowrap;
        th {
            color: $text-color-primary;
        }
        .header-cell {
            max-width: 100px;
        }
    }

    .table-row {
        .table-content,
        .table-content p,
        .beta-version-text {
            position: relative;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .beta-version-text {
            max-width: 38px;
        }

        .beta-version-icon {
            position: absolute;
            top: 0;
            right: 15px;
            width: 44px;
            height: 22px;
            transform: skew(-20deg);
            background: #ffa500;

            & p {
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                transform: skew(20deg);
                letter-spacing: 2px;
                font-size: 11px;
                text-transform: uppercase;
            }
        }

        & .table-element:nth-child(1) .table-content {
            min-width: 140px;
            width: calc(140px + (180 - 140) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(2) .table-content {
            min-width: 75px;
            width: calc(75px + (110 - 75) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(3) .table-content {
            min-width: 75px;
            width: calc(75px + (85 - 75) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(4) .table-content {
            min-width: 80px;
            width: calc(80px + (90 - 80) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(5) .table-content {
            min-width: 10px;
            width: calc(100px + (110 - 100) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(6) .table-content {
            min-width: 20px;
            width: calc(20px + (30 - 20) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(7) .table-content {
            min-width: 80px;
            width: calc(80px + (110 - 80) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(8) .table-content {
            min-width: 95px;
            width: calc(95px + (170 - 95) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(9) .table-content {
            min-width: 140px;
            width: calc(140px + (285 - 140) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(10) .table-content {
            min-width: 70px;
            width: calc(70px + (80 - 70) * (100vw - 1500px) / (1920 - 1500));
        }
        & .table-element:nth-child(n + 11):nth-child(-n + 13) .table-content {
            min-width: 60px;
            width: calc(60px + (65 - 60) * (100vw - 1500px) / (1920 - 1500));
        }
    }

    .table-element {
        max-width: 250px;
        min-width: 60px;
        padding: 10px;
        padding-left: 16px;
    }

    .table-element.flutter-icon {
        min-width: 20px;
        padding-left: 10px;
    }

    .align-items {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .gradient {
        position: absolute;
        right: 0px;
        width: 46px;
        padding-left: 40px;
        background-image: linear-gradient(
            90deg,
            rgba(246, 246, 246, 0) 0%,
            rgba(255, 255, 255, 0.5947421204810048) 50%,
            rgba(255, 255, 255, 1) 100%
        );
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .expand-panel {
        box-shadow: none;
        background-color: transparent;
    }

    .expand-icon {
        color: $secondary-color;
    }

    .display-description {
        display: block;
        width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .MuiExpansionPanelSummary-root {
        padding-left: 0px;
    }
}

.align-items {
    display: flex;
    align-items: center;
    justify-content: center;
}

.device-status {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 8px;
}

.active-device {
    background: $available-btn;
}

.inactive-device {
    background: #ff0000;
}

.empty-table {
    color: $tabs-text-header-color;
    text-align: center;
    border-bottom: none;
}
.pagination {
    position: absolute;
    width: 100%;
    padding: 0;
    bottom: 0;
    border-top: 1px solid #c0c0c0;
    background: #fff;
}

.footer-info {
    position: fixed;
    bottom: 17px;
    left: 17px;
    font-size: 17px;

    & .footer-info-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        & .footer-info-version {
            margin: 0 30px 0 0;
        }

        & .footer-info-group {
            display: flex;
            justify-content: center;
            align-items: center;

            & .footer-info-icon {
                width: 20px;
                height: 20px;
                color: #53a337;
            }
        }
    }
}
