@mixin controllTab($screen, $margin, $font, $width) {
    @media only screen and (max-width: $screen) {
        margin: $margin;
        font-size: $font;
        min-width: $width;
    }
}

@mixin switchControll($screen, $font) {
    @media only screen and (max-width: $screen) {
        font-size: $font;
    }
}

.container {
    .tabs-wrapper {
        padding: 15px 0;
        position: static;
        background-color: $text-color-primary;
        box-shadow: none;
        color: $tabs-text-header-color;
        height: 75px;
        .controll-tab {
            border: 2px solid #828282;
            border-radius: 10px;
            margin: 0 20px;
            @include controllTab(1250px, 0 15px, 13px, 140px);
            @include controllTab(1100px, 0 10px, 12px, 125px);
            @include controllTab(900px, 0 5px, 11px, 110px);
        }
        .controll-button-group {
            display: flex;
            align-items: center;
            margin: 0 0 0 30px;

            & svg {
                fill: #fff;
                stroke: #000;
                width: 30px;
                height: 30px;
                margin: 0 10px 0 0;
                cursor: pointer;
            }

            & svg.active-btn {
                fill: #4d9bd5;
                stroke: transparent;
            }
            & svg.disable-btn {
                fill: #9f9f9f;
                stroke: transparent;
            }
        }
        .switch-control {
            .MuiFormControlLabel-label {
                @include switchControll(1250px, 14px);
                @include switchControll(850px, 13px);
            }
        }
        .MuiSwitch-colorPrimary.Mui-checked {
            color: $secondary-color;
        }

        .Mui-selected {
            border: 2px solid #004a87;
            color: #004a87;
        }

        .MuiTabs-indicator {
            background: none;
        }
    }

    .tab-body {
        background: $tab-body-background-color;

        .MuiBox-root {
            padding: 0;
        }
    }
}
