.control-system-wrapper {
    display: flex;
    width: 100%;
    height: calc(100vh - 55px);
    align-items: center;
    justify-content: space-between;
    .tabs-wrap {
        height: 100%;
        width: 14%;
        min-width: 170px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding-top: 15px;
        background-color: #314f72f5;
        .tabpanel-wrap {
            height: 100%;
            width: 100%;
            .tabs-container {
                width: 100%;
                color: seashell;
                display: flex;
                align-items: center;
                overflow: hidden;
                min-height: 48px;
                .scrollable-container {
                    width: 95%;
                }
                .tabs-indicator {
                    background-color: $secondary-color;
                    width: 3px;
                }
            }
        }
    }
    .content-wrap {
        width: 88%;
        height: 100%;
        box-sizing: border-box;
        @media only screen and (max-width: 1215px) {
            width: calc(100% - 170px);
        }
        & .button-wrap {
            position: absolute;
            bottom: 30px;
            right: 30px;
            background-color: #4d9bd5;
            &:hover {
                cursor: pointer;
                background-color: #314f72;
            }
        }
        .device-header {
            background-color: #cad8e6;
            font-size: 13px;
            padding: 16px 7px;
            text-transform: uppercase;
            white-space: nowrap;
        }
        .tabcontent-wrap {
            height: 100%;
            .tab-content {
                height: 100%;
                .content-box {
                    height: 100%;
                    box-sizing: border-box;
                    overflow: auto;
                    .users-wrap {
                        height: 100%;
                        position: relative;
                        & .container {
                            overflow: auto;
                            height: 100%;
                            & .devices-wrap {
                                display: flex;
                                width: 100%;
                                box-sizing: border-box;
                                flex-direction: column;
                                & .device-row {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0 0 7px 0;
                                    box-sizing: border-box;
                                    padding: 2px 10px;
                                    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.07%),
                                        0px 1px 1px 0px rgba(0, 0, 0, 0.04%),
                                        0px 1px 3px 0px rgba(0, 0, 0, 0.09%);
                                    &:last-child {
                                        margin: 0;
                                    }
                                    & .device-cell {
                                        display: flex;
                                        align-items: center;
                                        font-size: 13px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        margin: 0 12px 0 0;
                                        &:last-child {
                                            margin: 0;
                                            flex: 0 1 80px;
                                        }
                                        & p,
                                        & li {
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        }
                                    }
                                    & .device-cell.device-title-cell {
                                        flex: 0 0 55px;

                                        @media screen and (min-width: 1800px) {
                                            flex: 0 1 auto;
                                        }
                                    }
                                    & .device-cell.device-name-cell {
                                        width: 160px;
                                        flex: 1 0 160px;
                                        flex-direction: column;
                                        align-items: flex-start;
                                        justify-content: center;

                                        .device-name-group {
                                            display: flex;
                                            align-items: center; 
                                            justify-content: flex-start; 
                                            width: 100%;
                                        }

                                        .device-name-list {
                                            padding: 0; 
                                            margin: 0; 
                                            list-style-type: none; 
                                            width: 100%;
                                        }
                                    }
                                    & .device-cell.device-id-cell {
                                        width: 165px;
                                        flex: 0 0 165px;

                                        @media screen and (min-width: 1800px) {
                                            width: 230px;
                                            flex: 1 0 230px;
                                        }
                                    }
                                    & .device-switch {
                                        display: flex;
                                        align-items: center;
                                        margin-right: 2px;
                                    }
                                }
                            }
                        }
                        .cell-root {
                            padding: 10px 7px;
                            .agent-version {
                                font-size: 12px;
                            }
                            .flutter-info {
                                white-space: nowrap;
                                font-size: 12px;
                            }
                        }
                        .agent-cell {
                            color: #395678;
                            font-weight: 500;
                        }
                        .action-cell {
                            .icon {
                                width: 20px;
                                font-size: 18px;
                                color: #5f5f5faf;
                                padding: 5px;
                                &:hover {
                                    color: $secondary-color;
                                    cursor: pointer;
                                }
                            }
                            &:hover {
                                background-color: transparent;
                            }
                        }
                        .popup {
                            position: relative;
                            width: 300px;
                            height: 320px;
                            margin: 0 auto;
                            cursor: default;
                            background: #fff;
                            padding: 30px 40px;
                            border-radius: 10px;
                            &.small {
                                height: 200px;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                justify-content: space-between;
                                p {
                                    font-size: 18px;
                                    text-align: center;
                                    padding: 5px 0;
                                    line-height: 25px;
                                    span {
                                        color: $secondary-color;
                                        font-weight: 600;
                                    }
                                }
                                .password-form {
                                    width: 100%;
                                    .error {
                                        font-size: 12px;
                                        color: red;
                                        text-align: left;
                                    }
                                }
                                .edit-model-wrap {
                                    text-align: center;
                                    width: 90%;
                                    .input-model {
                                        width: 100%;
                                        margin-bottom: 10px;
                                    }
                                }
                                .btn-wrap {
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    .upload-file-btn {
                                        height: 45px;
                                        width: 40%;
                                        border: 1px solid $secondary-color;
                                        border-radius: 15px;
                                    }
                                    .icn-ok {
                                        color: #53a337;
                                    }
                                    .icn-cancel {
                                        color: #b1081e;
                                    }
                                    .inactive {
                                        opacity: 0.65;
                                    }
                                }
                            }
                        }
                        .select-cell-wrap {
                            position: relative;
                            text-align: left;
                        }
                        .icon.inactive {
                            &:hover {
                                cursor: default;
                                color: #5f5f5faf;
                            }
                        }
                        .icon {
                            font-size: 18px;
                            color: #5f5f5faf;
                            &:hover {
                                color: $secondary-color;
                            }
                            &.check {
                                color: #53a337;
                                &:hover {
                                    color: $secondary-color;
                                }
                            }
                        }
                        &:hover {
                            background-color: transparent;
                        }
                        .role-select {
                            font-size: 13px;
                            &::before {
                                border-bottom: 1px solid #cad8e6;
                            }
                            &::after {
                                border-bottom: 1px solid $secondary-color;
                            }
                            & :focus {
                                background-color: transparent;
                            }
                            & .MuiSelect-select.Mui-disabled {
                                color: #000000ab;
                            }
                            .role-select-item {
                                padding: 5px;
                            }
                        }

                        .text {
                            font-size: 14px;
                        }

                        .role-select .MuiInputBase-input,
                        .input .MuiInputBase-input {
                            border: none;
                            padding: 4px 0;
                        }

                        .input .MuiInputBase-input {
                            font-size: 13px;
                            border-radius: 0;
                        }

                        .input.active .MuiInputBase-input {
                            border-bottom: 1px solid #cad8e6;
                            &:focus {
                                border-color: #4d9bd5;
                            }
                        }
                        .input.active.invalid .MuiInputBase-input {
                            border-bottom: 1px solid #b1081e;
                            &:focus {
                                border-color: #b1081e;
                            }
                        }
                    }
                }
            }
        }
        .add-btn-container {
            position: absolute;
            bottom: 30px;
            right: 30px;
            .add-btn {
                background-color: $secondary-color;
            }
        }
        .stickyHeader-custom {
            background-color: #cad8e6;
            font-size: 13px;
            text-transform: uppercase;
            white-space: nowrap;
        }
    }
}

.drawer-wrap {
    .drawer-container {
        background-color: #ffff;
        min-width: 300px;
        width: 25%;
        padding: 20px;
        box-sizing: border-box;
    }
}

.agent-settings,
.device-settings,
.device-relations {
    padding: 0;
    height: 24px;
    min-width: 50px;
    & .agent-menu-wrapper,
    & .device-menu-wrapper {
        height: 100%;
        min-height: 100%;

        & .icon-wrapper {
            padding: 5px;

            & .settings-icon {
                color: #314f72;
            }
        }
    }
}

ul.MuiList-root.MuiMenu-list {
    & li.folder-item.folder-menu-item,
    & li.settings-agent-item.agent-menu-item,
    & li.settings-device-item.device-menu-item,
    & li.settings-device-item.device-menu-ble {
        margin: 0;
        color: #000;
        border-radius: 0;
        line-height: 2.5;
        background: none;
        justify-content: flex-start;

        &:hover {
            background: rgba(0, 0, 0, 0.05);
        }

        & svg.folder-menu-icon,
        & svg.device-menu-icon,
        & svg.agent-menu-icon,
        & svg.device-relations-icon {
            background: none;
            margin: 0 16px 0 0;
            color: #314f72;
        }

        & svg.device-relations-icon {
            margin: 0;
        }

        & svg.folder-menu-icon {
            font-size: 22px;
        }
    }

    & li.settings-device-item.device-menu-ble {
        justify-content: center;
    }
}

div.agent-modal-wrapper {
    width: 100%;
    max-width: 360px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 0 5px 0;
    border-radius: 8px;
    background: #fff;

    & h2.agent-modal-title {
        margin: 0 0 10px 0;
        padding: 0;
        text-align: center;
    }

    & ul.agent-modal-list {
        margin: 0;
        padding: 0;

        & li.agent-modal-item {
            list-style: none;
            margin: 0 0 10px 0;
            padding: 0 50px;
            display: flex;
            justify-content: space-between;

            & span:nth-child(2) {
                font-weight: 700;
            }
        }
    }
}

div.device-dialog-wrapper {
    & div.device-dialog-content {
        max-height: 340px;
        padding: 0 24px 8px;

        & table.device-dialog-table {
            & thead.device-dialog-thead {
                position: sticky;
                top: 0;
                left: 0;
                z-index: 10;
                height: 52px;
                background: #cad8e6;
            }

            & tbody.device-dialog-tbody {
                & .device-dialog-small-cell,
                & .device-dialog-big-cell {
                    padding: 10px 16px;
                    box-sizing: border-box;

                    & .device-dialog-cell-content {
                        position: relative;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }

                & .device-dialog-small-cell {
                    max-width: 140px;
                }

                & .device-dialog-big-cell {
                    width: 200px;
                    max-width: 250px;
                }
            }
        }
    }

    & div.device-dialog-action {
        padding: 16px 24px;
    }
}

.device-cell {
    width: 20%;
    display: flex;
    align-items: center;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 12px 0 0;

    & .icon {
        cursor: pointer;
    }

    & .input,
    & .role-select {
        width: 100%;
    }

    & .MuiInputBase-root.Mui-disabled {
        color: #000;
    }
}

.MuiTableRow-root.customRow {
    vertical-align: top;
}

.popup-child-box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);

    & .popup-child-wrapper {
        width: 750px;
        padding: 30px 20px;
        background: #fff;

        & .popup-child-button {
            display: flex;
            justify-content: flex-end;
            margin: 20px 0 0 0;
        }
    }
}
