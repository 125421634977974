.MuiSvgIcon-root.reboot-confirm-icon,
.MuiSvgIcon-root.save-confirm-icon,
.MuiSvgIcon-root.expire-confirm-icon {
    width: 45px;
    height: 45px;
    margin: 0 5px 0 0;
    vertical-align: middle;
}

.MuiSvgIcon-root.reboot-confirm-icon,
.MuiSvgIcon-root.save-confirm-icon {
    fill: #578bc7;
}

.MuiSvgIcon-root.expire-confirm-icon {
    fill: #ffd000;
}

.MuiTypography-root.alert-dialog-info {
    display: block;
}

.select-path-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.stream-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    padding-top: 50px;

    .leftPannel,
    .rightPanel {
        display: flex;
        flex-direction: column;
        max-width: 40px;
    }

    .leftPannel {
        margin: 0 25px 0 0;
        & .screenshot-icon {
            margin-bottom: 5px;
        }

        .set-pin-code-icon {
            cursor: pointer;
            fill: #828282;
        }
        .set-pin-code-icon:hover {
            fill: #4d9bd5;
        }
    }

    &.disable {
        .MuiSvgIcon-root {
            &:hover {
                cursor: inherit;
                color: $tabs-text-header-color;
            }
        }
    }

    .inspector-enable,
    .inspector-disable,
    .MuiSvgIcon-root.action-lock-icon {
        position: absolute;
        bottom: 0;
        right: -5px;
        font-size: 14px;
        color: #706f6f;
        cursor: default;
        &:hover {
            cursor: inherit;
            color: #706f6f;
        }
    }

    .inspector-enable,
    .inspector-disable {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        bottom: 4px;
        right: 0px;
    }

    .inspector-enable {
        background: #53a337;
    }

    .inspector-disable {
        background: #ff0000;
    }

    .MuiSvgIcon-root {
        font-size: 2rem;
        color: $tabs-text-header-color;
        &:hover {
            cursor: pointer;
            color: $device-selected;
        }
    }

    .leftPannel,
    .rightPanel,
    .bottomPanel {
        & .MuiSvgIcon-root.screenshot-icon-not-active {
            opacity: 0.4;
            cursor: default;
            color: $tabs-text-header-color;
        }
    }

    .bottomPanel {
        position: absolute;
        left: 50%;
        bottom: -50px;
        transform: translateX(-50%);
        display: flex;
        width: 240px;
        justify-content: space-around;

        & div:first-child .screenshot-icon:not(.Mui-disabled) {
            color: #828282;
            border-color: #828282;

            &:hover {
                color: #4d9bd5;
                border-color: #4d9bd5;
                background: transparent;
            }
        }
    }

    .skin-wrapper {
        position: relative;
        user-select: none;
        z-index: 90;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 25px 0 0;
        .loading-stream {
            display: flex;
            justify-content: center;
            align-items: center;
            background: black;
            color: #fff;
            font-size: 22px;
        }
        svg {
            user-select: none;
            -webkit-user-drag: none;
        }
        #stream {
            -webkit-user-drag: none;
        }
        .galaxy-s-9,
        .iphone-xr {
            //To Do: updated classes according to activeDevice.model
            position: relative;
        }

        .galaxy-s-9-stream,
        .iphone-xr-stream {
            position: absolute;
            z-index: 9999;
            cursor: pointer;
            border-radius: 18px;
        }

        .galaxy-s-9-stream.notification,
        .iphone-xr-stream.notification {
            z-index: 10000;
            cursor: default;

            & p {
                position: absolute;
                z-index: 10000;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                color: #fff;
            }
        }

        @media (min-width: 1200px) {
            .galaxy-s-9,
            .iphone-xr {
                // left: 35%;
                width: 210px;
                height: 454px;
            }
            .galaxy-s-9-stream {
                // top: 20px;
                // right: 4.5px;
                // right: -3px;
                width: 191px;
                height: 418px;
            }
            .iphone-xr-stream {
                // top: 32px;
                // right: -2px;
                width: 170px;
                height: 390px;
            }
        }

        @media (max-width: 1199px) {
            .galaxy-s-9,
            .iphone-xr {
                // left: 35%;
                width: 210px;
                height: 454px;
            }
            .galaxy-s-9-stream {
                // top: 20px;
                // right: 4.5px;
                // right: -3px;
                width: 191px;
                height: 418px;
            }
            .iphone-xr-stream {
                // top: 32px;
                // right: -2px;
                width: 170px;
                height: 390px;
            }
        }
        @media (min-width: 1800px) {
            .galaxy-s-9,
            .iphone-xr {
                // left: 34%;
                width: 300px;
                height: 648px;
            }
            .galaxy-s-9-stream {
                // top: 5%;
                // left: 4px;
                width: 272px;
                height: 594px;
            }
            .iphone-xr-stream {
                width: 243px;
                height: 556px;
            }
        }

        .buttons > .hardware-btn:hover {
            fill: #828282;
            stroke: #828282;
            cursor: pointer;
        }
    }
}
