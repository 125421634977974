.login-page {
    background-image: url("../../static/images/medtronic.jpg");
    background-size: cover;
    height: calc(100vh - 90px);
    @media only screen and (min-width: 618px) {
        height: calc(100vh - 55px);
    }
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.logout {
    cursor: pointer;
}
.login-wrap {
    background-color: #1b1b20;
    height: 100%;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .login-title {
        color: #ffff;
        border-bottom: 1px solid #ffff;
    }
    .login-content {
        width: 75%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .login-form {
            height: 40%;     
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .by-cognito {
                text-align: center;
            }
            @media screen and (max-height: 900px) {
                height: 50%;
            }
            .error-login {
                margin-top: -30px;
                @media screen and (max-height: 900px) {
                    margin-top: -15px;
                }
            }
        }
    }
    .login-version {
        color: #fff;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        font-family: Effra;
        font-size: 14px;
    }
}

.login-wrapper {
    font-family: Effra;
    font-style: normal;
    background: #314f72f5;
    border-radius: 10px;
    height: 100%;
    color: #fff;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8%;
    .login-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .registration-form {
            width: 100%;
            height: 90%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            @media only screen and (max-width: 1250px) {
                height: 95%;
            }
        }
    }
}

.delimiter {
    border-bottom: 1px solid #fff;
}

.login-title {
    font-family: "Roboto";
    font-style: normal;
    font-size: 25px;
    padding: 20px 20px;
    text-transform: uppercase;
    @media only screen and (max-width: 1250px) {
        font-size: 18px;
        padding: 10px 20px;
    }
}

.login-recomendation {
    font-family: Effra;
    font-style: oblique;
    font-size: 18px;
    margin: 20px 0;
    width: 100%;

    .login-error {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        background: #f16363;
        border-radius: 5px;
        font-size: 14px;
        font-style: normal;
        margin: 0 0 10px 0;
        color: #ffffff;

        .login-error-group {
            display: flex;
            align-items: center;

            .login-error-report {
                margin: 0 10px 0 0;
            }
        }

        & svg {
            font-size: "20px";
        }

        .login-error-close {
            cursor: pointer;
        }
    }
}

.input-container {
    position: relative;
    display: block;
    padding: 9px;
    border: 1px solid $secondary-color;
    border-radius: 3px;
    @media only screen and (max-width: 1250px) {
        padding: 5px;
    }
}

.MuiSvgIcon-root.login-password-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: #8f8e8e;
    font-size: 20px;
    cursor: pointer;
}

.label {
    display: block;
    float: left;
    height: 20px;
    margin-top: -35px;
    padding: 2px 5px 2px 5px;
    color: #fff;
    font-size: 14px;
    overflow: hidden;
    font-weight: normal;
    font-family: Effra;
    @media only screen and (max-width: 1250px) {
        font-size: 12px;
        margin-top: -30px;
    }
}

.input {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    display: block;
    outline: 0;
    border: 0;
    padding: 0 35px 0 5px;
    border-radius: 3px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    @media only screen and (max-width: 1250px) {
        font-size: 12px;
    }
}

.error-login {
    text-align: left;
    font-size: 12px;
    height: 15px;
    color: #f99898;
    margin-top: 2px;
    @media only screen and (max-width: 1250px) {
        font-size: 10px;
        margin-top: 0;
    }
}

.login-submit {
    background-color: $secondary-color;
    color: #fff !important;
    width: 100%;
    &.MuiButton-text {
        margin-top: 20px;
        background-color: $secondary-color;
        @media only screen and (max-width: 1250px) {
            margin-top: 5px;
        }
    }

    &.MuiButton-text:last-child {
        margin-top: 0px;
    }
}

.login-separator {
    text-transform: uppercase;
    color: #fff;
    margin: -25px auto !important;
    font-size: 10px !important;

    @media screen and (max-height: 900px) {
        margin: -15px auto !important;
    }
}

.login-separator.hidden,
.login-submit.hidden {
    visibility: hidden;
}
