.preview-video {
    width: 100%;
    height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center;

    & .video-content {
        width: 250px;
    }
}

.preview-text {
    word-wrap: break-word;
    white-space: pre-wrap;
    padding: 10px;
    margin: 0;
}