.apple-watch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 55px);

    .watch-container {
        position: relative;
        .watch-button {
            transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
            &:hover {
                cursor: pointer;
                fill: #909092;
            }
        }
        .watch-message {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
        }
    }
}
