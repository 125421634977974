.scroll {
	/* width */
	::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
		border-radius: 60px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #828282;
		border-radius: 60px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #828282;
		border-radius: 60px;
	}
}

.dot {
	height: 10px;
	width: 10px;
	margin-right: 5px;
	background-color: #bbb;
	border-radius: 50%;
	display: table-cell;
	vertical-align: middle;
}

.dot.success {
	background: #008000;
}

.dot.error {
	background: #ff0000;
}

.summary-content{
	display: flex;
	align-items: center;
	width: 80%;
	justify-content: space-evenly;
}

.right-panel {
	@extend .scroll;
	border-left: 1px solid #bdbdbd;
	height: 100%;

	.tree-tab {
		height: calc(100vh - 104px);
	}
	.element-tree-wrap {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
}
	.element-trees-header {
		font-family: Effra;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 32px;
		color: $primary-color;
		padding: 14px 0;
		text-align: center;
		text-transform: uppercase;
		p {
			width: 100%;
		}
	}

	.parameters-table {
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 230px);
		@media only screen and (min-width: 618px) {
			height: calc(100vh - 170px);
		}
	}

	.parameters-cell {
		border-bottom: 0px !important;
	}

	.parameters {
		font-family: Effra;
		font-style: normal;
		font-size: 16px;
		line-height: 24px;
		color: $right-panel-main-color;
	}

	.parameters-key {
		@extend .parameters;
		font-weight: 800;
	}

	.parameters-value {
		@extend .parameters;
		font-weight: normal;
		padding-left: 25px !important;
	}

	.MuiTable-root {
		width: auto;
		table-layout: auto;
		margin-left: 25px;
		.MuiBox-root-19 {
			height: 100%;
		}
	}

	.MuiBox-root {
		position: relative;
		padding: 0 !important;
		height: 100%;

		.spiner-device-controll {
			.loader-wrapper {
				height: calc(100vh - 104px);
			}
		}

	}
	.aplication-activity {
		height: 80%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	.window {
		overflow-y: auto;
		max-height: calc(100vh - 260px);
	}

	.file-window {
		@extend .window;
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		.upload-file {
			display: none;
		}
		.upload-ico {
			height: 50px;
			padding: 15px;
			&:hover {
				cursor: pointer;
				background-color: $device-dashboard-list-bg;
				border-radius: 100%;
			}
		}
		.install-icon .MuiSvgIcon-root {
			cursor: pointer;
			font-size: 2rem;
			color: $tabs-text-header-color;
		}
	}

	.applications {
		padding: 10px 15px;
        box-sizing: border-box;
        margin: 1px 10px 10px;
		overflow: auto;
		height: 100%;
		border-radius: 10px;
		li {
			font-size: 16px !important;
		}
		.package-list {
			margin: 1px;
			.MuiPaper-elevation1 {
				box-shadow: none;
			}
		}
		.storage-list {
			margin: 1px;
			display: flex;
			.icon {
				display: flex;
				align-items: flex-start;
				cursor: pointer;
			}

			& .icon:first-child svg,
			& .icon:last-child svg {
				&:hover {
					color: #4d9bd5;
				}
			}
		}
	}

	.trees {
		overflow: auto;
		height: 50%;
		min-height: 50%;
		margin: 0 10px;
        padding: 5px;
	}

	.transfer {
		background: #828282;
		border-radius: 4px;
		display: flex;
		height: 20px;
		padding: 5px 7px;
		margin: 5px 5px;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
	}

	.file-manager {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
	}

	.file-change {
		cursor: pointer;
	}

	.file-item {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: $right-panel-main-color;

		.icon-action {
			margin-right: 5px;
			cursor: pointer;

			&:hover {
				color: #4d9bd5;
			}
		}
	}
}

.apllication-wrap {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.title {
		height: 20%;
		padding-bottom: 10px;
	}		
}

.rotate-180 {
	transform: rotate(180deg);
}

.file-list {
	list-style-type: none;
	margin-top: 0px;
	li {
		color: $right-panel-main-color;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
	}
	li:hover {
		cursor: pointer;
	}
}

.active-details {
	border-top: 1px solid #c4c4c4;
	padding-right: 40px;
	overflow: auto;
	font-size: 14px;
	margin-top: 10px;
	ul {
		list-style: none;
	}
}

.upload-wrap {
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed $tabs-text-header-color;
	border-radius: 10px;
	&:hover {
		border-color: #ffd700;
		.addIcon {
			color: #ffd700;
		}
		cursor: pointer;
	}
}
.creation-mode {
	@extend .scroll;
	padding: 0 0 !important;
}

.drawer {
	& .MuiDrawer-paper {
		z-index: 100;
		top: 55px;
		height: calc(100vh - 55px);
	}
}

.crm-wrap {
	padding: 15px 0;
	box-sizing: border-box;
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
		width: calc(505px + (640 - 505) * (100vw - 1520px) / (1920 - 1520));
		overflow: hidden;
		@media screen and (max-width: 1024px) {
			width: 350px;
		}
		@media screen and (max-width: 768px) {
			width: 300px;
		}
		.wrapper-header {
			width: 100%; 
			position: relative; 
			padding: 10px 0;
			& .title {
				height: 5%;
				text-align: center;
				padding-left: 10px;
				font-size: 18px;
				box-sizing: border-box;
				text-transform: uppercase;
				color: $primary-color;
				font-weight: 800;
			}
			& .drawer-close-btn {
				position: absolute;
				top: 50%; 
				right: 0;
				padding: 10px;
				transform: translateY(-50%);
			}
		}
		.output-text-field {
			width: 97%;
			padding: 10px;
			height: 72%;
			overflow: auto;
			.command {
				width: 100%;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				border-bottom: 1px solid $third-color;
				.resource-controls {
					margin-top: 12px;
				}
				.expand-panel {
					box-shadow: none;
					width: 90%;
					.summary {
						width: 100%;
						display: flex;
						align-items: center;
						padding: 0;
						justify-content: space-between;
						.MuiAccordionSummary-content.Mui-expanded{
                          margin-top: 12px;
						};
						.display-command {
							width: 90%;
							justify-content: flex-start;
							display: flex;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							& > p {
								margin-left: 2px;
								max-width: 70%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								}
						}					
					}
					.expand-content > .expand-content-info {
						width: 100%;
					}
				}
				
			}
			.copy-icon {
				width: 16px;
				color: $primary-color;
				&:hover {
					cursor: pointer;
					color: $device-selected;
				}
			}
		}

		.form {
			padding: 10px;
			box-sizing: border-box;
			width: 100%;
			text-align: center;
			.copyBox {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
					text-decoration-color: #4d9bd5;
				}
				.copy-wrap{
					display: flex;	
					align-items: center;
					justify-content: flex-start;
					.copyIcon {
						color: #4d9bd5;
						margin-right: 5;         
					}
				}
		
			}
			.form-container {
			  display: flex;
			  flex-direction: column;
			  align-items: center;
			  .text-field-multiline {
			    width: 100%;
				min-height: 80px;
				border-radius: 10px;
        		margin: 10px 0 20px;
				.MuiFilledInput-multiline {
					padding: 5px;
					min-height: 80px;
					font-size: 14px;
					border-radius: 10px;
					border: 1px solid $primary-color;
				}
				.MuiFilledInput-root {
				  background-color: #FFFF;
				}
				.MuiFilledInput-underline:before {
					border: none;
				}
				.MuiFilledInput-underline:after {
					border: none;
				}
			}
			  .execute-btn {
				height: 45px;
				width: 50%;
				background-color: $primary-color;
				border-radius: 4px;
				text-align: center;
				color: $text-color-primary;
				font-size: 14px;
				line-height: 17px;
			}
		    }
		}
	}
}